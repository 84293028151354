import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';
import { HeaderService } from 'src/app/service/header.service';
import { Enclass, ResTargetdata, ResultClass } from '../../models/encryption-class';
import { InputPara, GetGrowKPIDashboard, GetVPOORVISIScanDashboard, GetNewRLADashboard, BrandImage, ResNotificationList, PCMarketData, MarketData, ResValuePerformance, GetGrowKPI } from '../../models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { EncryptionService } from 'src/app/service/encryption.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  // @ViewChild('openModalChannel', { static: true }) openModalChannel: ElementRef;

  public resTargetdata: ResTargetdata[] = [];
  public resGrowKPI: GetGrowKPIDashboard[] = [];
  public resVCScan: GetVPOORVISIScanDashboard[] = [];
  public resNewRLA: GetNewRLADashboard[] = [];
  public marketData: MarketData = new MarketData();
  public pcmarketData: PCMarketData = new PCMarketData();
  public TodayData: ResValuePerformance = new ResValuePerformance();
  unbilledOutletType: string = "MTD";
  unbilledrural: number = 0;
  unbilledurban: number = 0;
  unbilledtotal: number = 0;
  input: InputPara = new InputPara();
  input2: InputPara = new InputPara();
  public brandImage: any;
  value: number = 50;
  userType: any = '';
  branchName: any;
  userName: any;
  areaName: any = '';
  token: any;
  UnbilledoutletDetails: any;
  UserID: any;
  TotalPC: number = 0;
  ActivePC: number = 0;
  TotalTSI: number = 0;
  ActiveTSI: number = 0;
  ActivePCPer: number = 0;
  ActiveTSIPer: number = 0;
  order: number = 0;
  todaysVisit: number = 0
  seearning: number = 0;
  greenperiodList: any;
  datatype: any = '';
  SeEarningData: any;
  GreenOutlets: any;
  greenTgt: number = 0;
  greenAch: number = 0;
  FormName: any;
  valuePerformanceForm = this.fb.group({
    period: [''],
  });
  period: any[] = [];
  periodForKPIEC: any[] = [];
  totalAchPercent: number = 0;
  dashboardValue: number = 0;
  selectedperiod: any;
  selectedTabIndex: number = 0;
  selectedperiodForKPIEC: any;
  unbilledselectedperiod: any;
  unbilledperiodList: any;
  result: any;
  ruralPer: any;
  urbanPer: any;
  selectedperiodForVCScan: any;
  periodForVCScan: any[] = [];
  tagName: string = '';
  ruralPerForVCScan: any = 0;
  urbanPerForVCScan: any = 0;
  deviceType: any;
  device: any;
  resultForVCScan: any;
  periodForNewRLA: any[] = [];
  selectedperiodForNewRLA: any;
  resultForNewRLA: any;
  ruralPerForNewRLA: any;
  urbanPerForNewRLA: any;
  resULB: any;
  resULBAll: any;
  cmulb: any = 0;
  cmulb_Total: any = 0;

  audited_rla: any = 0;
  vis_rla: any = 0;
  //defaultImageUrl:string="assets/UploadedImages/BrandDisplay/";
  defaultImageUrl: string = "https://cilsales.net/MeraApp2/BrandDisplay/";
  defaultImage: string = "assets/UploadedImages/BrandDisplay/MABD22.png";
  IsBanner: boolean = false;
  ClientID: any;
  Flag: any;
  notificationlist: ResNotificationList[] = [];
  IsShowNotification: boolean = true;
  FieldName: any;
  FieldValue: any;
  periodList: any[] = [];
  expansionbtnclass: any = '';
  expansionbtnclassforurjaa: string = '';
  vcscanbtnclass: string = '';
  mscorebtnclass: string = '';
  newrlabtnclass: string = '';
  vpobtnclass: string = '';
  greenoutletbtnclass: string = '';
  ulbbtnclass: string = '';
  productivitybtnclass: string = '';
  normalcss: string = 'btn_inactive';
  activecss: string = 'btn_active border-bottom-0 border-left-0 border-right-0 border-top-0';
  activecssforurjaa: string = 'btn_active border-bottom-0 border-left-0 border-right-0 border-top-0';
  activecssfordms: string = 'btn_active border-bottom-0 border-left-0 border-right-0 border-top-0';
  RequirementType: string = '';
  RequirementTypeForUrjaa: string = '';
  selectedApprovalCount: number = 0;
  selectedApprovalCountForUrjaa: number = 0;
  EC: number = 0;
  VCScan: number = 0;
  NewRLA: number = 0;
  VPO: number = 0;
  GreenOutlet: number = 0;
  ULB: number = 0;
  MScore: number = 0;
  Productivity: number = 0;
  VillageProductivity: number = 0;
  ECForUrjaa: number = 0;
  ULBForUrjaa: number = 0;
  VCScanForUrjaa: number = 0;
  Villages: number = 0;
  RuralLandscape: number = 0;
  ecforurjaabtnclass: string = '';
  vcscanforurjaabtnclass: string = '';
  ulbforurjaabtnclass: string = '';
  villagesbtnclass: string = '';
  rurallandscapebtnclass: string = '';
  greenselectedperiod: any;
  ruralAchforVCScan: any = 0;
  ruralTotforVCScan: any = 0;
  urbanAchforVCScan: any = 0;
  urbanTotforVCScan: any = 0;
  isNull: boolean = false;
  orderUnit: any;
  effectiveCoverageUrjaa: any;
  selectedperiodForUrjaaEC: any;
  selectedperiodForUrjaaVP: any;
  periodListForUrjaaEC: any[] = [];
  VScanUrjaa: any;
  selectedperiodForUrjaaVCScan: any;
  periodListForUrjaaVCScan: any[] = [];
  popslabListForVP: any[] = [];
  outletrangeListForVP: any[] = [];
  urjaaVP: any;
  selectedOutletRange: any;
  selectedpopslab: any;
  RequirementTypeForDMS: string = '';
  sales: number = 0;
  ECForDMS: number = 0;
  lines: number = 0;
  selectedApprovalCountForDMS: number = 0;
  expansionbtnclassfordms: string = '';
  ecfordmsbtnclass: string = '';
  linesbtnclass: string = '';
  dmsData: any;
  periodListForDMS: any[] = [];
  periodListForProductivity: any[] = [];
  selectedperiodDMS: any;
  selectedperiodProductivity: any;
  ulbUrjaa: any;
  cmulbUrjaa: any = 0;
  RLAType: any = 'With RLA';
  billslabListForVP: any[] = [];
  selectedbillRange: any;
  kpiProductivity: any;
  notificationImageUrl: any = "";
  OrderFlag: boolean = false;
  ActivePCflag: boolean = false;
  ActiveTSIFlag: boolean = false;
  userstatus: string | null = '';
  resMainTargetdata: any;
  Valueper: number = 0;
  ValueperFlag: boolean = false;
  userId: any;
  list: any = [];
  lat: any = 0;
  lng: any = 0;
  secretKey: any = ""
  clientId: any = ""
  Visitpercent: number = 0
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  constructor(private cs: CommonUtilitiesService, config: NgbCarouselConfig, private fb: FormBuilder, private router: Router, private headerService: HeaderService, private ds: DataService, private modalService: NgbModal, private mds: MongoDataService, private notifyMeService: NotifyMeService, private es: EncryptionService) {
    config.interval = 2000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;
  }

  ngOnInit(): void {

    this.userType = localStorage.getItem("UserType");
    this.userName = localStorage.getItem("UserName");
    this.userId = localStorage.getItem("UserID");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.headerService.setTitle(this.userName);
    this.headerService.setActiveURl('/home');
    this.areaName = "North";
    this.token = localStorage.getItem("TokenNo");
    this.UserID = localStorage.getItem("UserID");
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.Flag = "Meraapp";
    this.ClientID = localStorage.getItem("ClientID");
    this.defaultImageUrl = localStorage.getItem("baseappurl") + "MeraApp2/BrandDisplay/";
    this.notificationImageUrl = localStorage.getItem("baseappurl") + "MeraApp2/Notification/";
    this.dashboardValue = 0;
    // this.cs.setFieldNamevalue();
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.userstatus = localStorage.getItem("userstatus");
    console.log("userstatus", this.userstatus)
    this.FormName = 'Dashboard';
    this.selectedperiodForVCScan = this.selectedperiod = this.selectedperiodForVCScan = this.selectedperiodForKPIEC = this.greenselectedperiod = this.selectedperiodForUrjaaVP = this.selectedperiodForUrjaaEC = this.selectedperiodDMS = this.selectedperiodForUrjaaVCScan = this.selectedperiodProductivity = localStorage.getItem("currperiod");
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.dashboardValue.toString();
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    debugger
    this.GetGeoLocation();
    this.getImages();
    this.mds.getTargetAchivementValue(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.resMainTargetdata = res.filter((singleItem: { [x: string]: string; }) => singleItem['valuetype'] == "Total");
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))];
      this.SelectTargetAchivement();

    });
    this.getUserType();
    this.getGrowKPIData();
    this.GetNotofication();
    this.GetActivePCData();
    this.GetActivTSIData();
    this.GetTodaysOrder();
    this.GetTodaysVisited();
    this.GetSEEarning();
    this.GetGreenOutletData();
    this.GetunBilledData();
    this.expansionbtnclass = this.vcscanbtnclass = this.mscorebtnclass = this.newrlabtnclass = this.vpobtnclass = this.greenoutletbtnclass = this.ulbbtnclass = this.productivitybtnclass = this.normalcss;
    this.expansionbtnclassforurjaa = this.ecforurjaabtnclass = this.vcscanforurjaabtnclass = this.ulbforurjaabtnclass = this.villagesbtnclass = this.rurallandscapebtnclass = this.normalcss;
    this.expansionbtnclassfordms = this.ecfordmsbtnclass = this.linesbtnclass = this.normalcss;

    this.loadData();
    this.resetCSS();
    this.resetCSSForUrjaa();
    this.getVillageProductivityData();
    this.resetCSSForDMS();
    this.getDMSDashboardData('Sales');
  }


  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.userType,
      "formname": "home",
      "actiondesc": "home page",
      "fieldname": "home",
      "fieldvalue": "home",
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }
  getImages() {

    this.ds.MA_GetBrandDisplayData(0, "meraapp", this.token, "", this.device, this.deviceType, this.FormName).subscribe(res => {

      this.brandImage = res;
      if (this.brandImage.length > 0) {
        this.IsBanner = true;
      }
    });
  }

  getUserType() {
    this.mds.getUserDetails(this.userId, this.token, this.device, this.deviceType, 'Dashboard').subscribe(res => {
      this.list = res;
      console.log("list", this.list)
    });
  }
  GetNotofication(): void {
    this.ds.GetNotificationData(this.UserID, '0', this.Flag, 0, this.token, this.ClientID, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.notificationlist = res as ResNotificationList[];
    });
  }
  GetActivePCData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = "0";
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.mds.GetMarketData(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.TotalPC = res[0].totalNoOfPC;
      this.ActivePC = res[0].activePC;
      this.ActivePCPer = this.TotalPC > 0 ? (this.ActivePC / this.TotalPC) * 100 : 0;
      this.ActivePCflag = true;

    });
  }
  GetActivTSIData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = '0';
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.mds.GetTSIMarketData(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.TotalTSI = res[0].totalNoOfPC;
      this.ActiveTSI = res[0].activePC;
      this.ActiveTSIPer = this.TotalTSI > 0 ? (this.ActiveTSI / this.TotalTSI) * 100 : 0;
      this.ActiveTSIFlag = true;
    });
  }

  GetTodaysOrder() {
    debugger
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = '0';
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.mds.getTodaysOrder(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.TodayData = res;
      this.order = res[0].orderValue;
      this.orderUnit = res[0].unit;
      this.OrderFlag = true;
    });
  }
  RefreshTodaysOrderBooking() {
    this.GetTodaysVisited()
    this.GetTodaysOrder();
  }

  GetTodaysVisited() {
    debugger
    // var FieldName=""
    // if (this.FieldName.toUpperCase() == "SOTERRITORY") {
    //   FieldName = 'so'
    // }
    // if (this.FieldName.toLowerCase() == "asmarea") {
    //   FieldName = 'asm'
    // }

    let request = {
      "FieldName": this.FieldName,
      "FieldValue": this.FieldValue,
      "userId": this.UserID,
      "Datatype":"0"
    }
    console.log("request",request)
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.GetTodaysVisited(this.enclass, this.clientId, "todays visits").subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));
      console.log("response", response)
      this.Visitpercent = response[0].Visitpercent

    }, (error: any) => {
      console.log("error", error)
    });
  }
  GetSEEarning() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = "seincentive";
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.SeEarningData = [];

    this.mds.GetSEEarningData(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.SeEarningData = res;
      this.seearning = this.SeEarningData[0]?.totalincentive;
    });
  }
  GetGreenOutletData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = "0";
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;

    this.mds.getGreenOutlets(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.GreenOutlets = res;
      this.greenperiodList = [...new Set(res.map((greentgt: { period: any; }) => greentgt.period))].sort();
    });
  }
  filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }

  filterItemsOfGreenOutlet(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.greenselectedperiod)) : null;
  }
  filterItemsOfunbilledOutlet(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.unbilledselectedperiod)) : null;
  }
  changeMonth(e: any) {
    this.selectedperiod = e.source._value;
    this.SelectTargetAchivement();

  }
  SelectTargetAchivement() {
    this.resTargetdata = this.resMainTargetdata.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod));
    this.Valueper = this.resTargetdata[0].percentage;
    this.ValueperFlag = true;
    this.ValuePer = 0;
    this.counterAnimation();
  }

  openModalForChannel(modal: any) {
    this.modalService.open(modal, {
      size: 'sm',
      // backdrop: 'static',
      windowClass: '',
      keyboard: false,
      centered: true,
    });
  }

  clickOnSecTrend() {
    this.router.navigate(['/home/Secondarytrend/', this.FieldName, this.FieldValue, this.UserID]);
  }

  clickOnValuePerformance() {
    this.router.navigate(['/home/ValuePerformance/', this.selectedperiod]);
  }
  clickOnRD() {
    this.router.navigate(['/home/RdList']);
  }
  clickOnCategory() {
    this.router.navigate(['/home/category']);
  }
  clickOnTownClass() {
    this.router.navigate(['/home/category']);
  }
  clickOnEC() {

    this.router.navigate(['/home/EffectiveCoverage/', this.FieldName, this.FieldValue, "0", this.UserID, this.selectedperiodForKPIEC]);
  }
  clickOnVCScan() {
    this.router.navigate(['/home/Vpo/', this.FieldName, this.FieldValue, "VISISCANNED", "0", this.UserID, this.selectedperiodForVCScan]);
  }
  clickOnNewRLA() {
    this.router.navigate(['/home/NewRLA/', this.FieldName, this.FieldValue, "0", this.UserID, this.selectedperiodForNewRLA]);
  }


  clickOnVPO() {
    this.router.navigate(['/home/Vpo/', this.FieldName, this.FieldValue, "VPO", "0", this.UserID, this.selectedperiodForVCScan]);
  }

  clickOnGreenOutlet() {
    this.router.navigate(['/home/GreenOutlet/', this.FieldName, this.FieldValue, "details", this.UserID, this.greenselectedperiod]);
  }

  clickOnLines_MScore(Mscoretype: any) {

    this.router.navigate(['/home/MScoreData/', this.FieldName == "ALLINDIA" ? "INDIA" : this.FieldName,
      this.FieldValue == "ALLINDIA" ? "Branch" : this.FieldValue, "1", this.UserID, Mscoretype]);
  }
  clickOnLines(ULBtype: any) {

    this.router.navigate(['/home/ulb/', this.FieldName == "ALLINDIA" ? "INDIA" : this.FieldName, this.FieldValue == "ALLINDIA" ? "INDIA" : this.FieldValue, "0", this.UserID, ULBtype]);
  }

  clickOnPCAttendance() {
    this.router.navigate(['/home/pcattendance/', this.FieldName, this.FieldValue, "PC", this.UserID, localStorage.getItem("currperiod")]);
  }
  clickOnTSIAttendance() { this.router.navigate(['/home/tsiattendance/', this.FieldName, this.FieldValue, "tsi", this.UserID, localStorage.getItem("currperiod")]); }

  clickOnGateMeeting() {
    if (this.userType.toUpperCase() == "SO") {
      this.router.navigate(['/home/GateMeetingdata/', this.UserID, '0', this.FieldName, '0', '0']);
    } else {
      this.router.navigate(['/home/GateMeeting']);
    }
  }

  clickOnTodaysOrderBooking() {
    this.router.navigate(['/home/todayorderbooking/', this.FieldName, this.FieldValue, this.datatype, this.UserID]);
  }


  clickOnTodaysVisits() {
    debugger
    // if (this.FieldName.toUpperCase() == "SOTERRITORY") {
    //   this.FieldName = 'so'
    // }
    // if (this.FieldName.toLowerCase() == "asmarea") {
    //   this.FieldName = 'asm'
    // }

    this.router.navigate(['/home/TodaysVisits/', this.FieldName, this.FieldValue, '0', this.UserID]);
  }

  clickOnSEEarnings() {
    if (this.FieldName.toUpperCase() == "SOTERRITORY") {
      this.router.navigate(['/home/seearningdetail/', this.FieldName, this.FieldValue, "seincentive", "detail", this.UserID]);
    }
    else {
      this.router.navigate(['/home/seearning/', this.FieldName, this.FieldValue, "seincentive", "detail", this.UserID]);
    }
  }
  clickOnPCEarnings() {
    this.router.navigate(['/home/PcEarnings/', this.FieldName, this.FieldValue, "PC", this.UserID]);
  }
  clickOnTSIEarnings() {
    this.router.navigate(['/home/PcEarnings/', this.FieldName, this.FieldValue, "TSI", this.UserID]);
  }

  clickOnUnbilledOutlet() {
    this.router.navigate(['/home/UnbilledOutlet/', this.FieldName, this.FieldValue, "0", this.UserID, this.unbilledOutletType]);
  }

  clickOnL3M() {
    this.router.navigate(['/home/ltm/', this.FieldName, this.FieldValue, "l3m", this.UserID]);
  }

  clickOnL6M() {
    this.router.navigate(['/home/ltm/', this.FieldName, this.FieldValue, "l6m", this.UserID]);
  }

  clickOnVillageProductivity() {
    // this.router.navigate(['/home/UrjaaVP']);
    this.router.navigate(['/home/villageProductivityData/', this.FieldName, this.FieldValue, 'RURAL', this.UserID, this.selectedperiodForUrjaaVP, this.selectedOutletRange, this.selectedpopslab, this.selectedbillRange]);
  }

  clickOnUrjaaVCScan(e: any): void {

    this.router.navigate(['/home/urjaaVCScan/', this.FieldName, this.FieldValue, "VISISCANNED", 'RURAL', this.UserID, this.selectedperiodForUrjaaVCScan]);
  }

  clickOnVillages() {
    this.router.navigate(['/home/category']);
  }

  clickOnCommomPerformance(selected: any) {
    this.modalService.dismissAll();
    this.router.navigate(['/home/CommomPerformance'], { queryParams: { selected: selected } });
  }

  clickOnNPD() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/npd']);
  }
  clickOnActivePC() {
    debugger
    let isMarketWorkingViewClicked = "false";
    localStorage.setItem("isMarketWorkingViewClicked", isMarketWorkingViewClicked)
    this.router.navigate(['/home/pcdetailsmain/', this.FieldName, this.FieldValue, "details", this.UserID]);
  }
  onKPITabChanged(type: any) {
    let clickedIndexKPI = type;
    debugger;
    switch (clickedIndexKPI) {
      case 'EC': {
        break;
      }
      case 'VC Scan': {
        this.tagName = 'VISISCANNED';
        this.getVPOORVISIScanData();
        break;
      }
      case 'New RLA': {
        this.getNewRLAData();
        break;
      }
      case 'VPO': {
        this.tagName = 'VPO';
        this.getVPOORVISIScanData();
        break;
      }
      case 'Green Outlet': {
        this.GetGreenOutletData();
        break;
      }
      case 'ULB': {
        this.getUlbData();
        break;
      }
      case 'M Score': {
        this.getMscoreData();
        break;
      }
      case 'Productivity': {
        this.getProductivityData();
        break;
      }
      default: {
        console.log("Invalid choice");
        break;
      }
    }
  }
  onUrjaaTabChanged(type: any) {
    let clickedIndexKPI = type;
    switch (clickedIndexKPI) {
      case 'Village Productivity': {
        break;
      }
      case 'EC': {
        this.urjaaEC();
        break;
      }
      case 'VC Scan': {
        this.urjaaVCScan();
        break;
      }
      case 'ULB': {
        this.urjaaULB();
        break;
      }
      case 'Villages': {
        break;
      }
      case 'Rural Landscape': {
        break;
      }
      default: {
        console.log("Invalid choice");
        break;
      }
    }
  }
  getGrowKPIData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.dashboardValue.toString();
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;

    this.mds.getGrowKPIDashboard(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.resGrowKPI = res;
      this.periodForKPIEC = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();

      //  this.selectedperiodForKPIEC = this.periodForKPIEC[0];
      this.commonKPIEC();
    });
  }
  commonKPIEC() {
    this.result = this.filterItemsForKPIEC(this.resGrowKPI);
    this.result.forEach((element: { valuetype: string; percentage: any; }) => {
      if (element.valuetype == "RURAL") {
        this.ruralPer = element.percentage;
      }
      if (element.valuetype == "URBAN") {
        this.urbanPer = element.percentage;
      }
    });
  }
  filterItemsForKPIEC(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiodForKPIEC)) : null;
  }
  changeMonthKPIEC(e: any) {
    //  this.selectedperiodForKPIEC = e.source._value;
    this.commonKPIEC();
  }
  getVPOORVISIScanData() {

    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.tagName;
    this.input.Datatype = this.dashboardValue.toString();
    this.input.UserID = this.UserID;
    this.mds.getVPOORVISIScanDashboard(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.resVCScan = res;
      if (this.resVCScan.length == 0) {
        this.isNull = true;
      }
      this.periodForVCScan = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
      this.commonKPIVPOORVISIScan();
    });
  }
  commonKPIVPOORVISIScan() {
    this.resultForVCScan = this.filterItemsForVCScan(this.resVCScan);
    this.ruralPerForVCScan = 0;
    this.urbanPerForVCScan = 0;
    this.ruralAchforVCScan = 0;
    this.ruralTotforVCScan = 0;
    this.urbanAchforVCScan = 0;
    this.urbanTotforVCScan = 0;
    this.resultForVCScan.forEach((element: { valuetype: string; percentage: any; totalAch: any; totalOutlet: any }) => {
      if (element.valuetype == "RURAL") {
        this.ruralAchforVCScan = element.totalAch;
        this.ruralTotforVCScan = element.totalOutlet;
        this.ruralPerForVCScan = element.percentage;
      }
      if (element.valuetype == "URBAN") {
        this.urbanAchforVCScan = element.totalAch;
        this.urbanTotforVCScan = element.totalOutlet;
        this.urbanPerForVCScan = element.percentage;
      }
    });
  }
  filterItemsForVCScan(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiodForVCScan)) : null;
  }
  changeMonthVCScan(e: any) {
    this.selectedperiodForVCScan = e.source._value;
    this.commonKPIVPOORVISIScan();
  }
  changeMonthGreenOutlet(e: any) {
    this.greenselectedperiod = e.source._value;
  }
  clickOnActiveTSI() {
    this.router.navigate(['/home/tsidetailsmain/', this.FieldName, this.FieldValue, "1", this.UserID]);
  }
  getNewRLAData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.dashboardValue.toString();
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.mds.getNewRLADashboard(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.resNewRLA = res;
      this.periodForNewRLA = [...new Set(res.map((bill: { tagname: any; }) => bill.tagname))].sort();
      this.selectedperiodForNewRLA = this.periodForNewRLA[0];
      this.commonKPINewRLA();
    });
  }
  commonKPINewRLA() {
    this.resultForNewRLA = this.filterItemsForNewRLA(this.resNewRLA);
    this.resultForNewRLA.forEach((element: { valuetype: string; newrla: any; netrla: any; }) => {
      if (element.valuetype == "RURAL") {
        this.ruralPerForNewRLA = element.netrla;
      }
      if (element.valuetype == "URBAN") {
        this.urbanPerForNewRLA = element.newrla;
      }
    });
  }
  filterItemsForNewRLA(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['tagname'] == this.selectedperiodForNewRLA)) : null;
  }
  changeMonthNewRLA(e: any) {
    this.selectedperiodForNewRLA = e.source._value;
    this.commonKPINewRLA();
  }
  getUlbData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = "0";
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.input.TYPE = 'Billed';

    this.mds.getOverallUlbDashboard(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.resULBAll = res.filter((singleItem: { [x: string]: string; }) => singleItem['rdtype'].startsWith("URBAN"));
      this.cmulb = this.resULBAll[0].cmulb;
    });
    this.input2.FieldName = this.FieldName;
    this.input2.FieldValue = this.FieldValue;
    this.input2.TagName = "0";
    this.input2.Datatype = '0';
    this.input2.UserID = this.UserID;
    this.input2.TYPE = 'Billed';

    this.input2.TYPE = 'Overall';
    this.mds.getOverallUlbDashboard(this.input2, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.resULBAll = res.filter((singleItem: { [x: string]: string; }) => singleItem['rdtype'].startsWith("URBAN"));
      this.cmulb_Total = this.resULBAll[0].cmulb;
    });
  }

  getMscoreData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = "0";
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.input.TYPE = "auditedrla";

    this.mds.getMScoreDashboard(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.resULB = res;
      this.audited_rla = this.resULB[0].auditedrla;
      debugger;
    });

    this.input2.FieldName = this.FieldName;
    this.input2.FieldValue = this.FieldValue;
    this.input2.TagName = "0";
    this.input2.Datatype = '0';
    this.input2.UserID = this.UserID;
    this.input2.TYPE = "visirla";

    this.mds.getMScoreDashboard(this.input2, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.resULB = res;
      this.vis_rla = this.resULB[0].auditedrla;
      debugger;
    });
  }

  changeMonthULB(e: any) {
    if (e.source._value == 'cmulb') {
      this.cmulb = this.resULB[0].cmulb;
    } else if (e.source._value == 'lmulb') {
      this.cmulb = this.resULB[0].lmulb;
    }
  }
  getNotificationurl(): void {
    this.router.navigate(['/home/notifications/']);

  }
  CloseNotification() {
    this.IsShowNotification = false;
  }

  loadData() {
    if (this.RequirementType == '')
      this.selectedApprovalCount = this.EC;
    this.RequirementType = 'EC';
    if (this.RequirementType == 'EC') { this.selectedApprovalCount = this.EC; }
    else if (this.RequirementType == 'VC Scan') { this.selectedApprovalCount = this.VCScan; }
    else if (this.RequirementType == 'New RLA') { this.selectedApprovalCount = this.NewRLA; }
    else if (this.RequirementType == 'VPO') { this.selectedApprovalCount = this.VPO; }
    else if (this.RequirementType == 'Green Outlet') { this.selectedApprovalCount = this.GreenOutlet; }
    else if (this.RequirementType == 'ULB') { this.selectedApprovalCount = this.ULB; }
    else { this.selectedApprovalCount = this.Productivity; }

    if (this.RequirementTypeForUrjaa == '')
      this.selectedApprovalCountForUrjaa = this.VillageProductivity;
    this.RequirementTypeForUrjaa = 'Village Productivity';
    if (this.RequirementTypeForUrjaa == 'Village Productivity') { this.selectedApprovalCountForUrjaa = this.VillageProductivity; }
    else if (this.RequirementTypeForUrjaa == 'EC') { this.selectedApprovalCountForUrjaa = this.ECForUrjaa; }
    else if (this.RequirementTypeForUrjaa == 'VC Scan') { this.selectedApprovalCountForUrjaa = this.VCScanForUrjaa; }
    else if (this.RequirementTypeForUrjaa == 'ULB') { this.selectedApprovalCountForUrjaa = this.ULBForUrjaa; }
    else if (this.RequirementTypeForUrjaa == 'Villages') { this.selectedApprovalCountForUrjaa = this.Villages; }
    else { this.selectedApprovalCountForUrjaa = this.RuralLandscape; }

    if (this.RequirementTypeForDMS == '')
      this.selectedApprovalCountForDMS = this.VillageProductivity;
    this.RequirementTypeForDMS = 'Sales';
    if (this.RequirementTypeForDMS == 'Sales') { this.selectedApprovalCountForDMS = this.sales; }
    else if (this.RequirementTypeForDMS == 'EC') { this.selectedApprovalCountForDMS = this.ECForDMS; }
    else { this.selectedApprovalCountForDMS = this.lines; }
  }

  showDetails(type: string) {
    this.RequirementType = type;
    this.selectedApprovalCount = this.RequirementType == 'EC' ? this.EC : this.RequirementType == 'M Score' ? this.MScore : this.RequirementType == 'VC Scan' ? this.VCScan : this.RequirementType == 'New RLA' ? this.NewRLA : this.RequirementType == 'VPO' ? this.VPO : this.RequirementType == 'Green Outlet' ? this.GreenOutlet : this.RequirementType == 'ULB' ? this.ULB : this.Productivity;
    this.expansionbtnclass = this.mscorebtnclass = this.vcscanbtnclass = this.newrlabtnclass = this.vpobtnclass = this.greenoutletbtnclass = this.ulbbtnclass = this.productivitybtnclass = this.normalcss;
    this.resetCSS();
    debugger;
    this.onKPITabChanged(type)
  }

  resetCSS() {
    if (this.RequirementType == 'EC') { this.expansionbtnclass = this.activecss; }
    else if (this.RequirementType == 'M Score') { this.mscorebtnclass = this.activecss; }
    else if (this.RequirementType == 'VC Scan') { this.vcscanbtnclass = this.activecss; }
    else if (this.RequirementType == 'New RLA') { this.newrlabtnclass = this.activecss; }
    else if (this.RequirementType == 'VPO') { this.vpobtnclass = this.activecss; }
    else if (this.RequirementType == 'Green Outlet') { this.greenoutletbtnclass = this.activecss; }
    else if (this.RequirementType == 'ULB') { this.ulbbtnclass = this.activecss; }
    else { this.productivitybtnclass = this.activecss; }
  }

  showDetailsForUrjaa(type: string) {
    this.RequirementTypeForUrjaa = type;
    this.selectedApprovalCountForUrjaa = this.RequirementTypeForUrjaa == 'Village Productivity' ? this.VillageProductivity : this.RequirementTypeForUrjaa == 'EC' ? this.ECForUrjaa : this.RequirementTypeForUrjaa == 'VC Scan' ? this.VCScanForUrjaa : this.RequirementTypeForUrjaa == 'ULB' ? this.ULBForUrjaa : this.RequirementTypeForUrjaa == 'Villages' ? this.Villages : this.RuralLandscape;
    this.expansionbtnclassforurjaa = this.ecforurjaabtnclass = this.vcscanforurjaabtnclass = this.ulbforurjaabtnclass = this.villagesbtnclass = this.rurallandscapebtnclass = this.normalcss;
    this.resetCSSForUrjaa();
    this.onUrjaaTabChanged(type);
  }

  resetCSSForUrjaa() {
    if (this.RequirementTypeForUrjaa == 'Village Productivity') { this.expansionbtnclassforurjaa = this.activecssforurjaa; }
    else if (this.RequirementTypeForUrjaa == 'EC') { this.ecforurjaabtnclass = this.activecssforurjaa; }
    else if (this.RequirementTypeForUrjaa == 'VC Scan') { this.vcscanforurjaabtnclass = this.activecssforurjaa; }
    else if (this.RequirementTypeForUrjaa == 'ULB') { this.ulbforurjaabtnclass = this.activecssforurjaa; }
    else if (this.RequirementTypeForUrjaa == 'Villages') { this.villagesbtnclass = this.activecssforurjaa; }
    else { this.rurallandscapebtnclass = this.activecssforurjaa; }
  }
  changeunbilledOutlet(e: any) {

    this.unbilledOutletType = e.source._value;
    this.GetunBilledData();
  }
  GetunBilledData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.unbilledOutletType;
    this.input.Datatype = "0";
    this.input.UserID = this.UserID;

    this.mds.getUnbilledoutletDetails(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.UnbilledoutletDetails = res;
      this.unbilledperiodList = [...new Set(res.map((unbilledrla: { period: any; }) => unbilledrla.period))].sort();

      for (let i = 0; i < res.length; i++) {
        if (res[i].valuetype == "Total") { this.unbilledtotal = res[i].unbilledrla; }
        if (res[i].valuetype == "URBAN") { this.unbilledurban = res[i].unbilledrla; }
        if (res[i].valuetype == "RURAL") { this.unbilledrural = res[i].unbilledrla; }
      }
      this.unbilledtotal = this.unbilledurban + this.unbilledrural;
    });
  }
  RefreshActivePCTSI() {
    this.GetActivePCData();
    this.GetActivTSIData();
  }

  urjaaEC(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.dashboardValue.toString();
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.mds.GetGrowKPIUrja(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.effectiveCoverageUrjaa = res.filter((singleItem: { [x: string]: string; }) => singleItem['valuetype'] == "RURAL");
      this.periodListForUrjaaEC = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
    });
  }
  urjaaVCScan(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.dashboardValue.toString();
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;

    this.mds.geturjaaVCScan(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {


      this.VScanUrjaa = res.filter((singleItem: { [x: string]: string; }) => singleItem['valuetype'] == "RURAL");

      this.periodListForUrjaaVCScan = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
    });
  }

  PeriodChange(e: any): void {
    this.selectedperiodForUrjaaEC = e.source._value;
  }
  PeriodChangeUrjaaVCSCan(e: any): void {
    this.selectedperiodForUrjaaVCScan = e.source._value;
  }

  filterItemsOfTypeUrjaaEC(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiodForUrjaaEC)) : null;
  }
  filterItemsOfTypeUrjaaVCScan(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiodForUrjaaVCScan)) : null;
  }

  clickOnUrjaaEC(e: any): void {
    this.router.navigate(['/home/urjaaEffectiveCoverageData/', this.FieldName, this.FieldValue, e, this.UserID, this.selectedperiodForUrjaaEC]);
  }

  getVillageProductivityData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = "ALLINDIA";
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;

    this.mds.getVillagedata(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.urjaaVP = res;
      this.popslabListForVP = [...new Set(res.map((bill: { popslab: any; }) => bill.popslab))].sort();
      this.selectedpopslab = this.popslabListForVP[0];
      this.outletrangeListForVP = [...new Set(res.map((bill: { outletrange: any; }) => bill.outletrange))].sort();
      this.selectedOutletRange = this.outletrangeListForVP[0];
      this.billslabListForVP = [...new Set(res.map((bill: { billslab: any; }) => bill.billslab))].sort();
      this.selectedbillRange = this.billslabListForVP[0];


      console.log("Village Data", res.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiodForUrjaaVP && x['popslab'] == this.selectedpopslab && x['outletrange'] == this.selectedOutletRange && x['billslab'] == this.selectedbillRange)));

    });
  }

  outletrangeChange(e: any): void {
    this.selectedOutletRange = e.source._value;
  }
  billslabChange(e: any): void {
    this.selectedbillRange = e.source._value;
  }
  popslabChange(e: any): void {
    this.selectedpopslab = e.source._value;
  }

  filterItemsOfTypeUrjaaVP(type: any) {

    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiodForUrjaaVP && x['popslab'] == this.selectedpopslab && x['outletrange'] == this.selectedOutletRange && x['billslab'] == this.selectedbillRange)) : null;
  }
  showDetailsForDMS(type: string) {
    this.RequirementTypeForDMS = type;
    this.selectedApprovalCountForDMS = this.RequirementTypeForDMS == 'Sales' ? this.sales : this.RequirementTypeForDMS == 'EC' ? this.ECForDMS : this.lines;
    this.expansionbtnclassfordms = this.ecfordmsbtnclass = this.linesbtnclass = this.normalcss;
    this.resetCSSForDMS();
    this.onDMSTabChanged(type);
  }
  resetCSSForDMS() {
    if (this.RequirementTypeForDMS == 'Sales') { this.expansionbtnclassfordms = this.activecssfordms; }
    else if (this.RequirementTypeForDMS == 'EC') { this.ecfordmsbtnclass = this.activecssfordms; }
    else { this.linesbtnclass = this.activecssfordms; }
  }

  onDMSTabChanged(type: any) {
    let clickedIndexKPI = type;
    switch (clickedIndexKPI) {
      case 'Sales': {
        this.getDMSDashboardData(type);
        break;
      }
      case 'EC': {
        this.getDMSDashboardData(type);
        break;
      }
      case 'Lines': {
        this.getDMSDashboardData(type);
        break;
      }
      default: {
        console.log("Invalid choice");
        break;
      }
    }
  }

  getDMSDashboardData(tagname: any) {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = tagname;
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.mds.getDMSData(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.dmsData = res;
      this.periodListForDMS = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
    });
  }

  PeriodChangeDMS(e: any): void {
    this.selectedperiodDMS = e.source._value;
  }

  filterItemsOfTypeDMS(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiodDMS)) : null;
  }

  clickOnDMS(e: any): void {
    this.router.navigate(['/home/dmsData/', this.FieldName, this.FieldValue, e, this.UserID, this.selectedperiodDMS, this.input.TagName]);
  }

  urjaaULB(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = '0';
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;

    this.mds.getUlbDashboard(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.ulbUrjaa = res.filter((singleItem: { [x: string]: string; }) => singleItem['rdtype'] == "RURAL");
      console.log("ulbUrjaa", this.ulbUrjaa);
      if (this.ulbUrjaa.length > 0) {
        this.cmulbUrjaa = this.ulbUrjaa[0].cmulb;
      }
      console.log("cmulb", this.cmulbUrjaa);
    });
  }

  clickOnUrjaaULB() {
    // this.router.navigate(['/home/urjaaUlb/', 'INDIA', this.ulbUrjaa[0].paravalue, this.ulbUrjaa[0].rdtype, this.UserID]);
    this.router.navigate(['/home/urjaaUlb/', this.FieldName == "ALLINDIA" ? "INDIA" : this.FieldName, this.FieldValue == "ALLINDIA" ? "INDIA" : this.FieldValue, this.ulbUrjaa[0].rdtype, this.UserID]);
  }
  ChangeRLA(e: any): void {
    this.RLAType = e.source._value;

  }

  getProductivityData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.dashboardValue.toString();
    this.input.Datatype = this.dashboardValue.toString();
    this.input.UserID = this.UserID;

    this.mds.getProductivity(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.kpiProductivity = res;
      this.periodListForProductivity = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
    });
  }

  filterItemsOfTypeKPIProductivity(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiodProductivity)) : null;
  }

  clickOnProductivity() {
    this.router.navigate(['/home/kpiProductivity/', this.FieldName, this.FieldValue, '0', '0', this.UserID, this.selectedperiodProductivity]);
  }

  PeriodChangekpiProductivity(e: any): void {
    this.selectedperiodProductivity = e.source._value;
  }
  projectcount: number = 0;
  projectcountstop: any = setInterval(() => {
    this.projectcount++;
    if (this.projectcount >= this.order && this.OrderFlag) {
      this.projectcount = this.order;
      clearInterval(this.projectcountstop);
    }
  }, 10)

  todaysVisitedcount: number = 0;
  todaysVisitedStop: any = setInterval(() => {
    this.todaysVisitedcount++;
    if (this.todaysVisitedcount >= this.Visitpercent) {
      this.todaysVisitedcount = this.Visitpercent;
      clearInterval(this.todaysVisitedStop);
    }
  }, 10)

  ActivePCcounter: number = 0;
  ActivePCcounterstop: any = setInterval(() => {
    this.ActivePCcounter++;
    if (this.ActivePCcounter >= this.ActivePC && this.ActivePCflag) {
      this.ActivePCcounter = this.ActivePC;
      clearInterval(this.ActivePCcounterstop);
    }
  }, 1)

  ActiveTSIcounter: number = 0;
  ActiveTSIcounterstop: any = setInterval(() => {
    this.ActiveTSIcounter++;
    if (this.ActiveTSIcounter >= this.ActiveTSI && this.ActiveTSIFlag) {
      this.ActiveTSIcounter = this.ActiveTSI;
      clearInterval(this.ActiveTSIcounterstop);
    }
  }, 10)

  ValuePer: number = 0;
  ValuePercounterstop: any;
  counterAnimation() {
    this.ValuePercounterstop = setInterval(() => {
      this.ValuePer++;
      if (this.ValuePer >= this.Valueper && this.ValueperFlag) {
        this.ValuePer = this.Valueper;
        clearInterval(this.ValuePercounterstop);
      }
    }, 10)
  }

  modalClose() {
    this.modalService.dismissAll();
  }

  clickOnMarketWorking() {
    this.router.navigate(['/home/MarketWorking']);
  }
}
