import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';

@Component({
  selector: 'app-outlet-name-change-request',
  templateUrl: './outlet-name-change-request.component.html',
  styleUrls: ['./outlet-name-change-request.component.css']
})
export class OutletNameChangeRequestComponent implements OnInit {

  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  msg: any;
  private _searchText = '';
  searchText: any;
  token: any;
  FormName: any;
  filteredList: any = [];
  public UIDData: any = "";
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;
  secretKey: any;
  clientId: any;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  @ViewChild('UID', { static: true }) UID: ElementRef | undefined;
  Soterritory:any="";
  ASMArea:any="";
  constructor(private headerService: HeaderService, private es: EncryptionService,
    private cs: CommonUtilitiesService, private router: Router, public ds: DataService, private toastr: ToastrService, public mds: MongoDataService) { }

  ngOnInit(): void {
    this.FormName = 'OutletNameChange'
    this.headerService.setTitle('Outlet Name Change');
    this.headerService.setActiveURl('/outletNameChangeRequest');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "outletNameChange"
    this.actiondesc = "Outlet Name Change Request";
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.Soterritory = localStorage.getItem("Soterritory");
    this.ASMArea = localStorage.getItem("ASMArea");
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }



  GOBtn(UID: any) {
    debugger
    console.log("this.secretkey",this.secretKey)
    this.filteredList = ""
    if (UID.value == "") {
      this.toastr.warning("Please enter UID");
      this.filteredList = "";
      return;
    }

    let request = {
      "OutletCode": UID.value,
      "ASMArea":this.ASMArea,
      "SOTerritory":this.Soterritory
    }

    // this.es.WordKey = this.secretKey
    // this.es.Word = JSON.stringify(request);
    // this.enclass.buggerstring=""
    // this.enclass.buggerstring = this.es.encrypt(this.secretKey)


    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.GetOutletMasterDetails(this.enclass, this.token, this.device, this.deviceType, this.FormName, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response", response)
      // this.list = res;
      this.list = this.filteredList = response;
      console.log("filteredList", this.filteredList)

      if (this.filteredList?.length==0) {
        this.toastr.warning("Outlet UID not found")
      }
    }, (error) => {
      console.log("error", error)
    });
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changeOuteletName() {
    debugger
    if (this.filteredList?.length==0) {
      return
    }

    if(this.filteredList[0]?.RdType?.toLowerCase()=='mt'){
      this.toastr.warning("Outlet Name changes functionality not allowed for MT Outlets!!!!")
      return 
    }

    if(this.filteredList[0]?.RdType?.toLowerCase()=='rural' && this.filteredList[0]?.Re?.toLowerCase()!='sub stockist'){
      this.toastr.warning("Outlet Name changes functionality not allowed for Rural Outlets!!!!")
      return 
    }


    let request = {
      "OutletCode": this.UID?.nativeElement.value,
    }

    this.es.WordKey = this.secretKey
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.MA_CheckOutletNameChangeStatus(this.enclass, this.token, this.device, this.deviceType, this.FormName, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));
      console.log("response**************", response)

      if (response[0].Result?.toLowerCase() == "ok") {
        this.router.navigate(['home/outletNameChangeRequestSendOtp'])
        sessionStorage.setItem("OutletDetails", JSON.stringify(this.filteredList))
      } else {
        this.toastr.warning(response[0].Result)
        return;
      }
    }, (error) => {
      console.log("error", error)
    });

  }
}
